.cs-label {
  display: block;
  font-size: 0.857rem;
  line-height: 1.429rem;
  font-weight: $baseFontWeight;
  color: #80878E;
}

.cs-input, .cs-textarea {
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #C7CDCF;
  font-size: 1rem;
  color: #424A50;
  min-width: 0px;
  display: block;
  &:hover, &:focus {
    border-color: #545C63;
    outline: none;
  }
  &:not(:placeholder-shown) {
    border-color: #92999F;
  }
  &.success {
    border-color: #26D878;
  }
  &.error {
    border-color: #FC333D;
  }
  &:disabled {
    background: #E0E3E4;
    border-color: #E0E3E4;
    cursor: not-allowed;
  }
}

.cs-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.cs-input {
  padding: 0 .75rem;
  height: 40px;
}
.cs-textarea {
  padding: .75rem;
  resize: none;
  min-height: 124px;
}

.__checkbox-wrap {
  &:hover {
    .__checkbox-input:not(.__checkbox-disabled) {
      color: $red;
    }
  }
  display: inline-flex;
} 

.__radio-wrap {
  &:hover {
    .__radio-input:not(.__radio-disabled) {
      color: $red;
    }
  }
  display: inline-flex;
}

.input-success, .input-error {
  display: block;
  font-size: .75rem;
  display: flex;
  align-items: center;
  &:before {
    font-family: 'Material Icons';
    vertical-align: middle;
    font-size: 1rem;
    margin-right: .25rem;
  }
}
.input-success { 
  color: #26D878;
  &:before { 
    content: '\E86C';
  } 
}
.input-error { 
  color: #FC333D;
  &:before { 
    content: '\E000';
  } 
}

::-webkit-input-placeholder {
  color: #BBC2C4;
  font-weight: $baseFontWeight;
  font-size: .875rem;
}

::-moz-placeholder {
  color: #BBC2C4;
  font-weight: $baseFontWeight;
  font-size: .875rem;
}

:-ms-input-placeholder {
  color: #BBC2C4;
  font-weight: $baseFontWeight;
  font-size: .875rem;
}

:-moz-placeholder {
  color: #BBC2C4;
  font-weight: $baseFontWeight;
  font-size: .875rem;
}
