.bmpui-ui-audiotracksettingstogglebutton:hover,
.bmpui-ui-settingstogglebutton:hover,
.bmpui-ui-subtitlesettingstogglebutton:hover {
    -webkit-filter: drop-shadow(0 0 1px #fff);
    filter: drop-shadow(0 0 1px #fff)
}

.bmpui-on.bmpui-ui-audiotracksettingstogglebutton:hover,
.bmpui-on.bmpui-ui-settingstogglebutton:hover,
.bmpui-on.bmpui-ui-subtitlesettingstogglebutton:hover {
    -webkit-filter: drop-shadow(0 0 1px #fb0f3b);
    filter: drop-shadow(0 0 1px #fb0f3b)
}

.bmpui-ui-subtitle-overlay {
    pointer-events: none;
    text-align: center;
}

.bmpui-ui-subtitle-overlay.bmpui-hidden {
    display: none;
}/*

.bmpui-ui-subtitle-overlay .bmpui-cea608 .bmpui-ui-subtitle-label:nth-of-type(1n-1):after {
    content: normal;
    white-space: normal
}
*/
.bmpui-layout-max-width-400 .bmpui-ui-subtitle-label {
    font-size: 100%;
}
.bmpui-layout-max-width-600 .bmpui-ui-subtitle-label {
    font-size: 120%;
}
.bmpui-layout-max-width-800 .bmpui-ui-subtitle-label {
    font-size: 140%;
}
.bmpui-layout-max-width-1200 .bmpui-ui-subtitle-label {
    font-size: 160%;
}
.bmpui-ui-subtitle-label {
    position: absolute;
    bottom: 20% !important;
    left: 2em;
    right: 2em;
    font-size: 180%;
    line-height: 100%;
    text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, -1px 1px 0 #000, 0 1px 0 #000, 1px 1px 0 #000;
    overflow: visible !important;
}
.bmpui-ui-subtitle-overlay {
    font-size: 20px;
    color: white;
}